.LoadingBlock {
    display: inline-block;
    width: 100%;
    height: 72px;
    text-transform: capitalize;
    color: var(--light-grey);
    text-align: center;
}

.AnimatedLoadingBlock {
    display: inline-block;
    width: 100%;
    height: 72px;
    border-radius: 0.5rem;
    outline: 1px solid var(--grey);
    background: var(--light-grey);
    background: linear-gradient(110deg, var(--light-grey) 8%, white 18%, var(--light-grey) 33%);
    background-size: 200% 100%;
    animation: 2s shine linear infinite;
    text-transform: capitalize;
    color: var(--light-grey);
    text-align: center;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.LoadingProgress {
    padding-left: calc(50% - 7rem);
    height: 100%;
}
