.Toolbar > div {
    padding: 1rem;
    margin-bottom: 24px;
    margin-top: 24px;
}

.Toolbar section {
    margin-left: unset;
    margin-right: unset;
}

.Content {
    margin-left: 0 !important;
    color: var(--black);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    border: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    min-height: 40px;
}

.Content label {
    margin-bottom: 0;
}

.Content label span {
    color: var(--black) !important;
    font-weight: normal;
}

.Buttons {
    display: flex;
    justify-content: right;
    min-height: 40px;
}

.TextOnlyButton {
    color: var(--black) !important;
    align-items: center;
    border-radius: 0.5rem;
    padding: 12px !important;
    font-family: Roboto, sans-serif;
    margin: 0 2px !important;
    min-width: 96px;
}

.TextOnlyButton:focus, .TextOnlyButton:disabled:focus {
    color: var(--black);
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 3px #309ab5;
}

.TextOnlyButton:hover, .TextOnlyButton:disabled:hover {
    background: var(--grey);
}

.TextOnlyButton:hover:focus, .TextOnlyButton:disabled:hover:focus {
    background: var(--grey);
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 3px #309ab5;
}

.TextOnlyButton:active {
    color: white !important;
    background: var(--azure) !important;
}

.TextOnlyButton:active, .TextOnlyButton:hover, .TextOnlyButton:focus, .TextOnlyButton:disabled {
    border: 0 !important;
}

.TextOnlyButton:disabled {
    background: var(--grey) !important;
    color: white !important;
}

.ActionButton {
    margin-left: 8px;
    margin-top: 1px;
    margin-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 96px;
}

@media screen and (max-width: 796px) {
    .Content {
        justify-content: center;
    }

    .Buttons {
        justify-content: center;
        font-family: inherit !important;
    }

    .NoButtons {
        display: none !important;
    }
}
