.Toggle {
    margin-left: -2rem;
}

.Accordion h2 {
    text-transform: uppercase;
}

.AgentsInfo {
    display: inline-flex;
    text-transform: uppercase;
    vertical-align: top;
    padding: 3px 0;
    font-size: 1em;
    color: var(--azure);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
}

.ResponsiveDescription {
    font-weight: normal;
    font-size: 0.9em;
}

.GroupName {
    margin-bottom: 4px;
}

.SelectInput {
    margin: 0 !important;
}

.SelectInput select {
    border-radius: 0.5rem !important;
    height: 3rem !important;
    margin-bottom: 1.8rem !important;
}

.SelectInput span::after {
    top: 1.25rem !important;
}

:global(.spark-select--no-label select.spark-select__input) {
    padding: 0px 2.75rem 0rem 1rem !important;
    line-height: 2rem;
}

:global(.spark-input__field~span.spark-label) {
    transition: opacity 0.1s, top 0.2s;
}

.TextInput input {
    border-radius: 0.5rem !important;
    height: 3rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 1.8rem !important;
    font-weight: inherit !important;
}

.TextInput span {
    top: 0.5rem !important;
}

.TextInput input:focus ~ span {
    top: -2rem !important;
    opacity: 0;
}

.Placeholder {
    display: inline-block;
    width: 100%;
    text-align: center;
}
