@media screen and (max-width: 795px) {
    .GroupName {
        padding-bottom: 1rem;
    }

    .Title {
        font-weight: 700;
        font-size: 1.6666666667rem;
        line-height: 2rem;
        margin-bottom: 0.1rem;
    }

    .Element {
        padding-bottom: 12px;
        text-align: center;
    }
}

.GroupName {
    font-weight: normal;
    font-size: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
}

.EditorName {
    font-weight: normal;
    font-size: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Title {
    font-weight: 700;
    font-size: 1.6666666667rem;
    line-height: 2rem;
    color: var(--black);
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
}

.Placeholder {
    color: var(--dark-grey);
    text-transform: lowercase;
    font-weight: lighter;
}
