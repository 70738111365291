.Breadcrumbs {
    padding-left: 0.5rem;
    margin-top: 1px;
    height: 2.2rem
}

.BreadcrumbsLoadingSpinner {
    transform: scale(0.5) translateX(-2.25rem) translateY(-0.9rem);
}

.Toolbar > div {
    padding: 1rem;
    margin-bottom: 24px;
    margin-top: 24px;
}

.Toolbar section {
    margin-left: unset;
    margin-right: unset;
}