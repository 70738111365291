.Message {

}

.Message p {
    overflow-wrap: anywhere;
    max-height: 20vh;
    overflow: auto;
}

.MessageWithNoTitle p {
    padding-right: 80px;
}

.Button {
    position: absolute;
    top: 23px; /* yep, 23px */
    right: 24px;
}

.ButtonWithNoTitle {
    /*top: calc(50% - 8px)*/
}

.ButtonNew {
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Envelope i {
    top: 4px;
}

.Envelope {
    position: relative;
}

.Envelope button:hover {
    color: var(--azure);
}

.Envelope-error button {
    color: var(--red);
}

.Envelope-info button {
    color: var(--azure);
}

.Envelope-success button {
    color: var(--green);
}

.Envelope-warning button {
    color: var(--dark-yellow);
}
