.ProductAssigneesPanel {
    margin-bottom: 2rem;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.ProductAssigneesTable {
    margin-bottom: -5rem;
}

:global(.spark-modal__body .spark-message .spark-message__icon) {
    padding-top: 0;
}