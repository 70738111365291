.Modal {
    overflow-wrap: anywhere;
    overflow: hidden;
}

.Modal h1, .Modal h2, .Modal h3, .Modal h4, .Modal h5, .Modal h6 {
    margin-bottom: 2rem;
}

:global(.aat-regular-modal .spark-table__scroll) {
    overflow: hidden;
}
