.Background {
    background: white;
    width: 200%;
    height: 200%;
    z-index: 99998;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.6;
}

.Wrapper {
}

.LoadingProgress {
    justify-content: center;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 99999;
}
