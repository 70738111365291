.Splash {
    height: 110vh;
    display: flex;
    margin-top: -10vh;
}

.Message {
    margin: 30px 0 40px 0;
    justify-content: center;
}

@media screen and (min-height: 400px) {
    .Panel {
        min-height: 350px;
        margin-top: -30px;
    }
}