@media screen and (max-width: 1048px) {
    .Button {
        margin-left: 8px !important;
        margin-right: 0 !important;
        padding-left: 12px;
        padding-right: 12px;
    }
}

.Panel {
    z-index: 200;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
}

:global(.is-fixed-bottom + .spark-scroll-to-top) {
    position: fixed;
    bottom: 11rem;
}
