.Table > div {
    box-shadow: unset;
}

.Table thead tr th {
    padding: 1rem;
    border: 0;
    border-bottom: 1px solid var(--grey);
}

.Table tbody td, .Table tbody td:last-child {
    padding: 1rem;
    border: 0;
}

.Table tbody td:first-child {
    font-weight: bold;
}
