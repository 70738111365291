:root {
    --black: #121315;
    --white: #ffffff;
    --azure: #007b94;
    --grey: #e0e0e0;
    --dimming-grey: #a0a0a0;
    --light-grey: #f4f5f6;
    --dark-grey: #767676;
    --almost-black: #444444;
    --red: #d13630;
    --dark-red: #b9221f;
    --green: #008247;
    --dark-yellow: #a75f00;
    --shadow: rgb(18 19 21 / 15%);
    --transparent: rgb(0 0 0 / 0);
}

html, textarea {
    scrollbar-color: var(--azure) var(--transparent) !important;
    scrollbar-width: thin !important;
}

h1:focus {
    outline: 0;
    border-left: 2px solid lightgray;
    margin-left: -7px;
    padding-left: 5px;;
}

h1 + .spark-panel {
    margin-top: 2rem
}

.swagger-ui hgroup.main h2.title {
    text-align: center;
    font-size: 2.3rem;
}

.swagger-ui h3 a span {
    font-size: 1.8rem;
}

.swagger-ui .responses-inner div > h4 {
    width: 100%;
    border-bottom: 2px var(--black) dotted;
    font-size: 1.8rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
}

.swagger-ui hgroup.main a {
    display: block;
    text-align: center;
}

.swagger-ui .info {
    margin: 2rem 0 !important;
}

body {
    /* partly defined in public/index.html */
}

body:has(.aat-maximized-modal), body:has(.aat-regular-modal) {
    overflow-y: hidden;
}

.invisible {
    display: none;
}

.translucent {
    opacity: 0;
}

.non-clickable-table-row tr {
    cursor: auto !important;
}

.notification-animate {
    -webkit-animation: move 500ms ease-in-out;
    animation: move 500ms ease-in-out;
}

@-webkit-keyframes move {
    0% {
        margin-top: 63px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes move {
    0% {
        margin-top: 63px;
    }
    100% {
        margin-top: 0;
    }
}