.Message {
    margin-bottom: 0;
    display: flex;
}

.Message > p {
    flex-grow: 1;
    text-align: left;
}

.Message > i {
    flex-grow: 1;
    text-align: right;
}