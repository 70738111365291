.LoadingWrapper {
    display: block;
    position: fixed;
    width: 80%;
    text-align: center;
    left: 10%;
    top: calc(50% - 108px)
}

.TimeoutWrapper {
    padding: 2rem;
}
