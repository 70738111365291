.DialogPresenter {
    position: fixed;
    top: 72px;
    left: 0;
    width: 100%;
    z-index: 10000;
    padding: 0 24px;
    transition: top 0.2s linear;
}

.DialogPresenter > div {
    margin-top: -18px;
    margin-bottom: calc(18px + 12px);
}
