.TooltipNotification {
    color: white;
    background: var(--black);
    position: fixed;
    padding: 2px 14px;
    border-radius: 8px;
}

.TooltipNotification header, .TooltipNotification p {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    padding: 0 2px;
    margin: 0;
    display: inline-block;
}

.TooltipNotification header {
    font-weight: bold;
}

.TooltipNotification p {
    font-weight: normal;
}
