.TextArea textarea {
    font-weight: 700;
    max-height: 50vh;
    min-height: 50vh;
}

.TextArea > span {
    width: calc(100% - 1.5rem) !important;
    padding: 5px 8px 8px 8px !important;
    margin: -5px 0 0 -8px !important;
    border-radius: 8px;
    background: linear-gradient(var(--light-grey) 80%, var(--transparent) 100%);
}

.TextArea[data-error="true"] > span {
    background: unset !important;
}

.TextArea textarea:active ~ span, .TextArea textarea:focus ~ span, .TextArea textarea:hover ~ span {
    background: linear-gradient(var(--white) 80%, var(--transparent) 100%);
}