.Toggle {
    margin-top: -6px;
    flex-wrap: nowrap;
    display: inline-flex;
    /*margin-left: -2rem;*/
}

.ZoomOutIcon {
    transform: scale(0.6);
}

.ZoomOut {
    composes: ZoomOutIcon;
}

.ZoomOutText {
    transform: scale(0.75);
}

:global(.aat-toggle .spark-toggle .spark-toggle__input~.spark-label:nth-child(n)) {
    transition: background-color 0.1s ease-out;
    font-weight: normal;
}

:global(.aat-toggle--green .spark-toggle .spark-toggle__input:checked~.spark-label:nth-child(n)) {
    background: var(--green);
}

:global(.aat-toggle--red .spark-toggle .spark-toggle__input:checked~.spark-label:nth-child(n)) {
    background: var(--red);
}

:global(.aat-toggle--azure .spark-toggle .spark-toggle__input:checked~.spark-label:nth-child(n)) {
    background: var(--azure);
}

:global(.aat-toggle--yellow .spark-toggle .spark-toggle__input:checked~.spark-label:nth-child(n)) {
    background: var(--dark-yellow);
}

:global(.aat-toggle--grey .spark-toggle .spark-toggle__input:checked~.spark-label:nth-child(n)) {
    background: var(--dark-grey);
}

:global(.aat-toggle--black .spark-toggle .spark-toggle__input:checked~.spark-label:nth-child(n)) {
    background: var(--black);
}

:global(.aat-toggle--white .spark-toggle .spark-toggle__input:checked~.spark-label:nth-child(n)) {
    background: var(--white);
}
