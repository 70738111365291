.Icon:focus {
    -webkit-box-shadow: 0 0 !important;
    box-shadow: 0 0 !important;
    background-color: #309ab5 !important;
}

.Icon:focus:hover {
    background-color: #309ab5 !important;
}

.Icon {
    padding: 0;
}

.Separator {
    margin: 24px 0 12px 0;
}

.PopoverMenu ul a {
    font-size: 1.25rem;
}

:global(.active.spark-popover__content) {
    transition: opacity 250ms;
    opacity: 0;
}
