.HorizontalSeparator {
    color: var(--dark-grey);
}

.VerticalSeparator {
    display: inline-block;
    transform: rotate(90deg);
    color: var(--dark-grey);
}

.DotSeparator {
    padding: 0.5rem;
    color: var(--dark-grey);
}
