.Modal {
    padding: 0;
    margin: 0;
    display: inline-block;
}

.ModalXButton {
    font-size: 1.33rem !important;
    padding: 0 !important;
    width: 2.33rem !important;
}

.ActionPanelTop {
    z-index: 9999;
    min-height: 6rem;
}

.ActionPanelTop header {
    font-weight: 700;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.6666666667rem;
}

.ActionPanelBottom {
    z-index: 9999;
}

.Content {
    overflow: auto;
    position: fixed;
    left: 1.33rem;
    right: 1.33rem;
    top: 4em;
    bottom: 9rem;
    padding: 0 1rem;
    background: var(--white);
    box-shadow: 0 0 0.5rem 0.5rem var(--shadow);
    opacity: 0.99;
}

@media screen and (max-width: 544px) {
    .Content {
        padding: 0 0 33vh 0;
        bottom: 15rem;
        left: 0;
        right: 0;
    }
}
