.SearchSection > div {
    margin-top: 24px;
    margin-bottom: -12px;
}

.Header {
    margin-top: 24px;
    margin-bottom: 24px;
}

.InputsWrapper {
    display: flex;
    margin-right: 12px;
    margin-left: 12px;
}

.SelectInput {
    margin-right: 12px;
    width: 260px;
}

.TextInput {
    width: 260px;
}

.SearchButton {
    color: var(--black) !important;
    align-items: center;
    border-radius: 0.5rem;
    padding: 12px !important;
    font-family: Roboto, sans-serif;
    margin: 0 2px !important;
    min-width: 96px;
}

.SearchButton:focus, .SearchButton:disabled:focus {
    color: var(--black);
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 3px #309ab5;
}

.SearchButton:hover, .SearchButton:disabled:hover {
    background: var(--grey);
}

.SearchButton:hover:focus, .SearchButton:disabled:hover:focus {
    background: var(--grey);
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 3px #309ab5;
}

.SearchButton:active {
    color: white !important;
    background: var(--azure) !important;
}

.SearchButton:active, .SearchButton:hover, .SearchButton:focus, .SearchButton:disabled {
    border: 0 !important;
}

.SearchButton:disabled {
    background: var(--grey) !important;
    color: white !important;
}

.SearchButtonWrapper {
    height: 60px;
    display: flex;
    align-items: center;
}

.CloseButton {
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.CloseButton:before {
    font-size: 1.5rem;
}