.SelectInput {
    margin: 0 !important;
}

.SelectInput select {
    border-radius: 0.5rem !important;
    height: 3rem !important;
    margin-bottom: 1.8rem !important;
}

.SelectInput span::after {
    top: 1.25rem !important;
}

:global(.spark-select--no-label select.spark-select__input) {
    padding: 0px 2.75rem 0rem 1rem !important;
    line-height: 2rem;
}

:global(.spark-input__field~span.spark-label) {
    transition: opacity 0.1s, top 0.2s;
}

.TextInput input {
    margin-top: -5px;
    border-radius: 0.5rem !important;
    height: 3rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-weight: inherit !important;
    text-align: left !important;
}

.TextInput span {
    top: 0.5rem !important;
}

.TextInput input:focus ~ span {
    text-align: left !important;
    top: -1.8rem !important;
    opacity: 0;
}

:global(.aat-table-input .spark-input__field[data-typeahead]~.spark-input__placeholder) {
    display: none;
}

:global(.aat-table-input .spark-input__field[data-typeahead]:focus~.spark-input__placeholder) {
    background: unset !important;
    color: var(--dimming-grey);
    display: unset;
}
